import './Login.css';
import Email from './Email';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Password from './Password';
import SubButton from './SubButton';
import SignIn from './SignIn';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from '../Home/navbar';
function Login(props) {
    return (
        <div className='App'>
          <Navigation/>
        <Card className="Login">
        
        <Card.Body>
          <Card.Title className='Header'>Login</Card.Title>
          <Email/>
        <Password/>
        <div className='row'>
              <div className='col'>
                <SignIn/>
              </div>
               <div className='col'>
                <div className='sButton'>
               <SubButton/> 
               </div>
               </div>
        
        </div>
        </Card.Body>
      </Card>

      </div>
    );
}
export default Login;