import React from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let params = useParams();
        let navigate = useNavigate();
        return (
            <Component
            {...props}
            location = {location}
            params = {params}
            navigate = {navigate}
            
            />
        );
    }
    return ComponentWithRouterProp;

}

export default withRouter;