import axios from 'axios';

//const TAG_API_BASE_URL = "http://localhost:8888/rest/v1/tags";
const TAG_API_BASE_URL = "https://kabra.katoma-lab.at/rest/v1/tags";
const headers = {
    //'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'mode' : 'no-cors'
};

class TagService {

    getTags(){
        return axios.get(TAG_API_BASE_URL + '/all');
    }

    createTag(tag){
        return  fetch(TAG_API_BASE_URL + '/create', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(tag) // body data type must match "Content-Type" header
          });
    }

    getTagById(tagId){
        return axios.get(TAG_API_BASE_URL + '/byid/' + tagId);
    }

    updateTag(tag, tagId){
       return  fetch(TAG_API_BASE_URL + '/update/' + tagId, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(tag) // body data type must match "Content-Type" header
      });

    }

    deleteTag(tagId){
        //return axios.delete(TAG_API_BASE_URL + '/' + tagId);
        return  fetch(TAG_API_BASE_URL + '/delete/' + tagId, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify('') // body data type must match "Content-Type" header
          });
    }
}

export default new TagService()