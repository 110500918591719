import Button from 'react-bootstrap/Button';
import './Login.css';
import { Link } from 'react-router-dom';
function SignIn(props) {
    return(
        <div>
        <Link to = "../signin" className='Link'>
        <Button variant="dark" type="submit">
        Erstelle ein Konto
        </Button>
        </Link>
        </div>
);
}
export default SignIn;