import Button from 'react-bootstrap/Button';
import './Login.css';
import { Link } from 'react-router-dom';
function SubButton(props) {
    return(
    <div>
    <Link to = "../">
    <Button variant="dark" type="submit">
    Einloggen
    </Button>
    </Link>
    </div>
);
}
export default SubButton;