import Form from 'react-bootstrap/Form';
import './Home.css'
function Insures(props) {
    return (
        <div className='insures'>
            <img src={props.img} className='img-fluid'alt='Bild' />
            <Form.Check aria-label="option 1" className='checkbox'/>
        </div>
    );
}
 
export default Insures;