
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocation, Link} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import React from 'react'

function Search(props) {
   
   return (
<div className='align-self-center '>
        <Form className="d-flex rounded-pill">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"             
              aria-label="Search"
            />
        </Form>       
    </div>
    )}
    export default Search;