import ListGroup from 'react-bootstrap/ListGroup';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import Picture from './Picture';
import './List.css'

function Litem(props) {
    return (
        <div>
            <Link to ="../card" state = {props.Item} className='name'>
                <ListGroup.Item>
                    <div className='ListAlign'>
                        <div className='row'>
                            <div className='col col-md-2' >
                                <div className='Picture'>
                                    <Picture logo= {props.Item.imageurl} />
                                </div>
                            
                            </div>
                            <div className='col'>
                                <h5 className='name'>{props.Item.vorname} {props.Item.nachname}</h5>

                                <h6 className='name'>{props.Item.standort}</h6>
                                <h6 className='name'>{props.Item.wartezeit} Wartezeit</h6>
                                
                            </div>
                        </div>
                    </div>
                </ListGroup.Item>
            </Link>
        </div>
    );
  }
  
  export default Litem;