import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Home.css'
import Check from './Checkbox'
import Insures from './insures';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form'
function Filter() {
  return (
    <Card className='Filter'>
      
      <Card.Body>
        <Card.Title>Filter</Card.Title>
        <hr/>
        <p>An welchen Stellen haben Sie ein anliegen?</p>
        <div className='row'>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        <div className='col'>
        <Insures img='./images/user/Brust.png'/>
        </div>
        </div>
        <p>Welche Art von schmerz Spüren Sie?</p>
        <div className='row'>
        <div className='col'> 
        <Form>
        <Check name='Stechend'/>
        <Check name='Brennend'/>
        <Check name='Drückend'/>
        </Form>
        </div>
        <div className='col' >
          <div class="sButton">
        <Link to = "../list">
          <Button variant="dark" type="submit" >
            Suchen
         </Button>
        </Link>
        </div>
        </div>
        </div>
        
      </Card.Body>
    </Card>
  );
}

export default Filter;