import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {BsFillPersonFill} from 'react-icons/bs'
import { BrowserRouter, Routes, Route,Link } from 'react-router-dom';
import {FaSignInAlt} from 'react-icons/fa'

function Navigation(props) {
   
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#home">
            <Link to='../' class="Link">
              Kabra
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              
              <Nav.Link href="#features">Eins</Nav.Link> 
              <Nav.Link href="#pricing">Zwei</Nav.Link>
              <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Über uns</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">
                <Link to='/signin' class="Link">
                  
                  <FaSignInAlt/> Registrieren
                  </Link>
                  </Nav.Link>
                
              <Nav.Link eventKey={2} href="#memes">
                <Link to='/login' class="Link"><BsFillPersonFill/> Login
              </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
     );
    }
     export default Navigation;