import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocation, Link} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import './Home.css'
import Search from './Searchbar';
import Navigation from './navbar';
import Filter from './Filter'
import BookForm from '../input';


function Home(props) {
    return (
        <div className='App'>
            <div className='text-self-align center'> 
            <Navigation/>
            </div>
                <div className='Searchbar'>
                <Search Item ={props.Item}/>
            </div>
            <Filter/>
           
           
        </div>
    );
}
 
export default Home;