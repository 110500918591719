import Form from 'react-bootstrap/Form';
function Name(props) {
    return (
        <Form className='Password'>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            
            <Form.Control type="input" placeholder="Nutzername" />
          </Form.Group>
          
          
        </Form>
      );
}export default Name;