import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import Head from './header';
import Karte from './Karte';
import Karte2 from './Karte2';
import {useLocation} from "react-router-dom";
import Navigation from '../Home/navbar';

function AboutMe(props) {
  const location = useLocation();
const cur = location.state;
    return (
      
       <div className="App">
        <Navigation/>
        <div>
          <Head name= 'Resume'/>
        </div>
        
        <div className='row'>
       
          <Karte Item={cur}/>
          <Karte2 Item={cur}/>
        </div>
      </div>
   
    );
  }
 
  export default AboutMe;