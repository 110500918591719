import Form from 'react-bootstrap/Form';
function Password(props) {
    return (
        <Form className='Password'>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            
            <Form.Control type="password" placeholder="Passwort" />
          </Form.Group>
          
          
        </Form>
      );
}export default Password;