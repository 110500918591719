import axios from 'axios';

const USERART_API_BASE_URL = "http://localhost:8888/rest/v1/userarts";


class UserArtService {

    getUserArts(){
        return axios.get(USERART_API_BASE_URL + '/all');
    }

    createUserArt(userart){
        return axios.post(USERART_API_BASE_URL, userart);
    }

    getUserArtById(userartId){
        return axios.get(USERART_API_BASE_URL + '/byid/' + userartId);
    }

    updateUserArt(userart, userartId){
        return axios.put(USERART_API_BASE_URL + '/' + userartId, userart);
    }

    deleteUserArt(userartId){
        return axios.delete(USERART_API_BASE_URL + '/' + userartId);
    }
}

export default new UserArtService()