
import Card from 'react-bootstrap/Card';
import Name from './name';
import 'bootstrap/dist/css/bootstrap.min.css';

import BezeichnungWert from './BezeichnungWert';

import Verhaeltnis from './Verhältnis';

import './Card.css'

function Karte2(props) {
  return (
    <Card className='Card2'>
      <Card.Body>
        <div style={{marginBottom: '30px'}}>
          <Card.Title><Name name = 'Über mich'/></Card.Title>
          <hr/>
        
        </div>
        <div>
          <BezeichnungWert Bezeichnung1 = 'Vorname' Wert1 = {props.Item.vorname} Bezeichnung2 = 'Nachname' Wert2 = {props.Item.nachname}/>
          <BezeichnungWert Bezeichnung1 = 'Standort' Wert1 = {props.Item.standort} Bezeichnung2 = 'Geschlecht' Wert2 = {props.Item.geschlecht}/>
          <BezeichnungWert Bezeichnung1 = 'Fortbildung' Wert1 = {props.Item.fortbildung} Bezeichnung2 = 'Ausbildung' Wert2 = {props.Item.ausbildung}/>
          <BezeichnungWert Bezeichnung1 = 'Zusatz- qualifikationen' Wert1 = {props.Item.zusatzqualifikationen} Bezeichnung2 = 'Wartezeit' Wert2 = {props.Item.wartezeit}/>
          <Verhaeltnis aktiv = {props.Item.aktiv} passiv = {props.Item.passiv}/>
          <BezeichnungWert Bezeichnung1 = 'Kinesiotape' Wert1 = {props.Item.kinesiotape} Bezeichnung2 = 'Nimmt Akutpatienten' Wert2 = {props.Item.akut}/>
          <BezeichnungWert Bezeichnung1 = 'Kassentherapeut' Wert1 = {props.Item.kassa} Bezeichnung2 = 'Wahltherapeut' Wert2 = {props.Item.wahlth}/>
          <BezeichnungWert Bezeichnung1 = 'Trainings- möglichkeiten' Wert1 = {props.Item.training} Bezeichnung2 = 'Gruppenpraxis' Wert2 = {props.Item.gruppenpr}/>
        </div>
      </Card.Body>
    </Card>
  );
}
export default Karte2;