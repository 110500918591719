import React, { Component } from 'react'
import { redirect, useParams } from 'react-router-dom';
import withRouter from '../../../withRouter';
import { Link} from 'react-router-dom';
import TagService from '../../../services/tagsservice';


class CreateTagsComponent extends Component {
    constructor(props) {
        super(props)
        console.log('LOG: ' ,this.props)
        this.state = {
            // step 2
            id: this.props.params.id,
            tag: '',
        }
        this.changeTagHandler = this.changeTagHandler.bind(this);
        this.saveOrUpdateTag= this.saveOrUpdateTag.bind(this);
    }
    
   
    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '_add'){
            return
        }else{
            TagService.getTagById(this.state.id).then( (res) =>{
                let tag = res.data;
                console.log('LOGUser: ', tag)
                this.setState({id: tag.idtags,
                    tag: tag.tag,
                    
                });
            });
        }        
    }
    saveOrUpdateTag = (e) => {
        e.preventDefault();
      
        let tag = {tag: this.state.tag};
        console.log('tag => ' + JSON.stringify(tag));

        // step 5
        if(this.state.id === '_add'){
            TagService.createTag(tag).then(res =>{
                this.props.navigate ("/tags")  
            });
        }else{
            TagService.updateTag(tag, this.state.id).then( res => {
                this.props.navigate ("/tags")  
            });
        }
    }
    
    changeTagHandler= (event) => {
        this.setState({tag: event.target.value});
    }


    cancel(){
        <Link to="/" />
    }

    getTitle(){
        if(this.state.id === '_add'){
            return <h3 className="text-center">Add Employee</h3>
        }else{
            return <h3 className="text-center">Tag ändern </h3>
        }
    }
    render() {
        //const {id } = useParams();
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                {
                                    this.getTitle()
                                }
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Tag: </label>
                                            <input placeholder="Bezeichnung" name="tag" className="form-control" 
                                                value={this.state.tag} onChange={this.changeTagHandler}/>
                                        </div>
                                     
                                        <button className="btn btn-success" 
                                            onClick={this.saveOrUpdateTag}>Speichern</button>
                                        <button className="btn btn-danger" 
                                            onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Abbruch</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default withRouter(CreateTagsComponent);