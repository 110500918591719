import ListGroup from 'react-bootstrap/ListGroup';
import Litem from './ListItem';
import './List.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useState, useEffect } from "react";
import Navigation from '../Home/navbar';
import Search from '../Home/Searchbar';



function Liste(props) {

  


    return (
    <div className='App'>
        <Navigation/>
       <div className='Search'>
       <Search/>
    </div>
      <ListGroup className='List'>
          {props.Item.map((cur) => (
              <Litem Item = {cur}  />
          ))}
      </ListGroup >
    </div>
    );
}
 
export default Liste;