
import './Card.css'

function Picture(props) {
 
  return(
     <div className="Picture">

      <img src= {props.logo} className="img-fluid" width= "100%;" alt="Profilbild"/>  
    </div>
    )

  }

  export default Picture;