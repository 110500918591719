import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import EmployeeService  from '../../../services/employservice'

class ListEmployeeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                users: []
        }
        this.addEmployee = this.addEmployee.bind(this);
        this.editEmployee = this.editEmployee.bind(this);
        this.deleteEmployee = this.deleteEmployee.bind(this);
    }

    deleteEmployee(id){
        EmployeeService.deleteEmployee(id).then( res => {
            this.setState({user: this.state.user.filter(user => user.iduser !== id)});
        });
    }
    viewEmployee(id){
        this.props.history.push(`/view-employee/${id}`);
    }
    editEmployee(id){
        /*this.props.history.push(`/add-employee/${id}`); */
        <Link to={`/add-employee/${id}`}></Link>
    }

    componentDidMount(){
        EmployeeService.getEmployees().then((res) => {
            this.setState({ users: res.data});
        });
    }

    addEmployee(){
        this.props.history.push('/add-employee/_add');
    }

    render() {
        return (
            <div>
                 <h2 className="text-center">User Liste</h2>
                 <div className = "row">
                    <button className="btn btn-primary" onClick={this.addEmployee}> Add Employee</button>
                 </div>
                 <br></br>
                 <div className = "row">
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> Vorname</th>
                                    <th> Nachname</th>
                                    <th> EMail</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.users.map(
                                        user => 
                                        <tr key = {user.iduser}>
                                             <td> { user.name} </td>   
                                             <td> { user.email}</td>
                                             <td> {user.email}</td>
                                             <td>
                                             <Link to="/add-user/1">
                                                 <button className="btn btn-info"> Update </button>
                                                 </Link>
                                                 
          
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteEmployee(user.iduser)} className="btn btn-danger">Delete </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewEmployee(user.iduser)} className="btn btn-info">View </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListEmployeeComponent