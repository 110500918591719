import Form from 'react-bootstrap/Form';
function Email(props) {
    return (

        <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
        </Form.Text>
        </Form.Group>
          </Form>
    );
}export default Email;