import './List.css'

function Picture(props) {
 
  return(
    <div className="Picture">
      <img src= {props.logo} className="img-thumbnail" width= "100%;" alt="Profilbild"/>  
    </div>
    )
  }
  export default Picture;