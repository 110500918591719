import './Card.css'
function Resume(props) {
    return (
    <div className="col">
        <div className="row">
            <div className="col">
                <p className="h5">{props.Bezeichnung}</p>
            </div>
            
            <div className="col">
                <h6 className="Info">{props.Wert}</h6>
            </div>
        </div> 
    </div>
    );   
    

  }

  export default Resume;