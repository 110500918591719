import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import UserArtService from '../../../services/userartservice'

class ListUserArtComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                userarts: []
        }
        this.addUserArt = this.addUserArt.bind(this);
        this.editUserArt = this.editUserArt.bind(this);
        this.deletUserArt = this.deleteUserArt.bind(this);
    }

    deleteUserArt(id){
        UserArtService.deleteEmployee(id).then( res => {
            this.setState({userarts: this.state.userarts.filter(userarts => userarts.iduserart !== id)});
        });
    }
    viewUserArt(id){
        this.props.history.push(`/view-employee/${id}`);
    }
    editUserArt(id){
        /*this.props.history.push(`/add-employee/${id}`); */
        <Link to={`/add-employee/${id}`}></Link>
    }

    componentDidMount(){
        UserArtService.getUserArts().then((res) => {
            this.setState({ userarts: res.data});
        });
    }

    addUserArt(){
        this.props.history.push('/add-employee/_add');
    }

    render() {
        return (
            <div>
                 <h2 className="text-center">User Liste</h2>
                 <div className = "row">
                    <button className="btn btn-primary" onClick={this.addEmployee}> Add Employee</button>
                 </div>
                 <br></br>
                 <div className = "row">
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> Art</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.userarts.map(
                                        userart => 
                                        <tr key = {userart.iduserart}>
                                             <td> { userart.art} </td>   
                                             <td>
                                             <Link to="/add-user/1">
                                                 <button className="btn btn-info"> Update </button>
                                                 </Link>
                                                 
          
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deletUserArt(userart.iduserart)} className="btn btn-danger">Delete </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewUserArt(userart.iduserart)} className="btn btn-info">View </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListUserArtComponent