import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import TagssService from '../../../services/tagsservice'

class ListTagsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                tags: []
        }
        this.addTag = this.addTag.bind(this);
        //this.editTag = this.editTag.bind(this);
        this.deleTag = this.deleteTag.bind(this);
    }

    deleteTag(id){
        TagssService.deleteTag(id).then( res => {
            this.setState({tags: this.state.tags.filter(tags => tags.idtags !== id)});
        });
    }
    viewTag(id){
        this.props.history.push(`/view-tag/${id}`);
    }
    

    componentDidMount(){
        TagssService.getTags().then((res) => {
            this.setState({ tags: res.data});
        });
    }

    addTag(){
        //this.props.history.push('/add-tag/_add');
        <Link to={`/add-tag/_add`}></Link>
    }

    render() {
        return (
            <div>
                 <h2 className="text-center">Tag Liste</h2>
                 <div className = "row">
                 <Link to={`/add-tag/_add`}><button className="btn btn-primary" onClick={this.addTag}> Neue Tag...</button></Link>
                 </div>
                 <br></br>
                 <div className = "row">
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> # Tag</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.tags.map(
                                        tags => 
                                        <tr key = {tags.idtags}>
                                             <td> { tags.tag} </td>   
                                             <td>
                                             <Link to={ "/add-tag/" + tags.idtags}>
                                                 <button className="btn btn-info"> Ändern </button>
                                                 </Link>
                                                 
          
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteTag(tags.idtags)} className="btn btn-danger">Delete </button>
                                              <Link to = {"/view-tag/" + tags.idtags}>   
                                              <button style={{marginLeft: "10px"}} 
                                                     className="btn btn-info">View </button>
                                                     </Link>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListTagsComponent