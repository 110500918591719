
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Card.css' 
function Verhaeltnis(props) {
  return (
    <div className= "Progress">
      <h4>Verhältnis zwischen aktiven und passiven Übungen</h4>
      
      <ProgressBar>
        <ProgressBar now={props.aktiv} key={1} />
        <ProgressBar variant="danger" now={props.passiv} key={2} />
      </ProgressBar>
    </div>
  );
}

export default Verhaeltnis;