import './SignIn.css'
import Card from 'react-bootstrap/Card';
import Name from './Username';
import Email from './Email';
import Password from './Password';
import SubButton from './SubButton';
import Navigation from '../Home/navbar';
function SignIn(){
    return(
        
        <div className='App'>
            <Navigation/>
             <Card className="SignIn">
        
        <Card.Body>
          <Card.Title className='Header'>Konto Erstellen</Card.Title>
        <Name/>
        <Email/>
        <Password/>
        <SubButton/>
        </Card.Body>
      </Card>
        </div>
    )
}export default SignIn