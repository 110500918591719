
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AboutMe from './components/karte/AboutMe';
import Liste from './components/liste/Liste';
import React from 'react';
import { BrowserRouter, Routes, Route, Switch, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useState, useEffect } from "react";
import Home from './components/Home/homepage';
import Login from './components/login/Login';
import SignIn from './components/signin/signIn';
import CreateEmployeeComponent from './components/config/user/createUser';
import ListEmployeeComponent from './components/config/user/list';
import ListUserArtComponent from './components/config/userart/userartlist';
import ListTagsComponent from './components/config/tags/taglist';
import CreateTagComponent from './components/config/tags/createTag';
import ViewTagComponent from './components/config/tags/ViewTag';

/*const people = [
  {
    id: 1,
    vorname: 'Daniel',
    nachname: 'Brandlak',
    erfahrung:'15 Jahre',
    standort: 'Wurmsdobl 8, 4771 Sigharting',
    wartezeit: '3 Wochen',
    geschlecht: 'männlich',
    fortbildung: 'Lymphdrainge, Kinesiotherapie',
    ausbildung: '2005, BSF für Physiotherapie Rotthalmünster - Deutschland',
    zusatzqualifikationen: 'Osteopathie',
    aktiv: 35,
    passiv: 65,
    kinesiotape: 'ja',
    akut: 'nein',
    kassa: 'nein',
    wahlth: 'ja',
    training: 'ja',
    gruppenpr: 'nein',
    imageurl: 'images/user/DanielBrandlak.jpg',
   

  },
  {
    id: 2,
    vorname: 'Alexander',
    nachname: 'kaiser',
    erfahrung:'5 Jahre',
    standort: 'Gerhagen 1a, 4772 Lambrechten',
    wartezeit: '4 tage',
    geschlecht: 'männlich',
    fortbildung: 'genotapie, Kinesiotherapie',
    ausbildung: '2017 Studium Wien',
    zusatzqualifikationen: 'Osteopathie',
    aktiv: 55,
    passiv: 45,
    kinesiotape: 'ja',
    akut: 'ja',
    kassa: 'ja',
    wahlth: 'nein',
    training: 'ja',
    gruppenpr: 'nein',
    imageurl: 'images/user/AlexanderKaiser.jpg',
    

  },
  {
    id: 3,
    vorname: 'Jo',
    nachname: 'Ma',
    erfahrung:'5 Jahre',
    standort: 'Linz',
    wartezeit: '4 tage',
    geschlecht: 'männlich',
    fortbildung: 'genotapie, Kinesiotherapie',
    ausbildung: '2017 Studium Wien',
    zusatzqualifikationen: 'Osteopathie',
    aktiv: 55,
    passiv: 45,
    kinesiotape: 'ja',
    akut: 'ja',
    kassa: 'ja',
    wahlth: 'nein',
    training: 'ja',
    gruppenpr: 'nein',
    imageurl: 'images/user/JoMa.jpg',
    

  },
  {
    id: 4,
   
    wartezeit: '4 tage',
    geschlecht: 'männlich',
    fortbildung: 'genotapie, Kinesiotherapie',
    ausbildung: '2017 Studium Wien',
    zusatzqualifikationen: 'Osteopathie',
    aktiv: 55,
    passiv: 45,
    kinesiotape: 'ja',
    akut: 'ja',
    kassa: 'ja',
    wahlth: 'nein',
    training: 'ja',
    gruppenpr: 'nein',
    imageurl: 'images/user/JosefKaschach.jpg',
    

  },
  {
    id: 5,
    vorname: 'Arnold',
    nachname: 'Einböck',
    erfahrung:'Hilfarbeiter',
    standort: 'St.Agatha',
    wartezeit: '-',
    geschlecht: 'weiblich',
    fortbildung: 'genotapie, Kinesiotherapie',
    ausbildung: '2017 Studium Wien',
    zusatzqualifikationen: 'Osteopathie',
    aktiv: 55,
    passiv: 45,
    kinesiotape: 'ja',
    akut: 'ja',
    kassa: 'ja',
    wahlth: 'nein',
    training: 'ja',
    gruppenpr: 'nein',
    imageurl: 'images/user/DanielBrandlak.jpg',
    

  },
  
  

]
*/



var x = 0



function App() {

  const [people, setPeople] =useState([]);
 
   const router = createBrowserRouter([
     {
       path: "/",
       element: <Home Item = {people}/>
     },
     {
       path: "/list",
       element: <Liste Item={people} />
     },
    {
      path: "/user",
      element:  <ListEmployeeComponent/>
    },
    {
      path: "/add-user/:id",
      element: <CreateEmployeeComponent/>
    }
    ,
    {
      path: "/userarts",
      element: <ListUserArtComponent />
    }
    ,
    
    {
      path: "/tags",
      element: <ListTagsComponent />
    }
    ,
    {
      path: "/add-tag/:id",
      element: <CreateTagComponent/>
    }
    ,
    {
      path: "/view-tag/:id",
      element: <ViewTagComponent />
    }
    ,
    {
      path: "/login",
      element: <Login/> 
    }
   ])

  return (
    <div className="App">
      <RouterProvider router={router} />
  
 <BrowserRouter>
    <Routes>
      
      <Route path="/card" element={<AboutMe Item={people}/>}/>
     
      <Route path="/signin" element={<SignIn/>}/>
      <Route path = "/add-user/:id" component = {CreateEmployeeComponent}></Route>
                        
    </Routes>
    </BrowserRouter>
</div>

    
  
  ); 
  
}

export default App;
