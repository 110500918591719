import './Card.css'
function BezeichnungWert(props) {
    return (
    <div className="row">
    <div className="col">
        <div className="row">
            <div className="col">
                <h4>{props.Bezeichnung1}</h4>
            </div>
            <div className="col">
                <h5 className='Info'>{props.Wert1}</h5>
            </div>
        </div> 
    </div>
    <div className="col">
        <div className="row">
            <div className="col">
                <h4>{props.Bezeichnung2}</h4>
            </div>
            <div className="col">
                <h5 className='Info'>{props.Wert2}</h5>
            </div>
        </div> 
    </div>

    </div>
    );   
    

  }

  export default BezeichnungWert;