import Form from 'react-bootstrap/Form';
function Check(props) {
    return (
        
      
        
          <Form.Check 
            type='radio'
            id={`default-radio`}
            label={props.name}
            className='radio'
            name="group1"
            
            />
    );
}

 
export default Check;