
import Card from 'react-bootstrap/Card';
import Name from './name';
import 'bootstrap/dist/css/bootstrap.min.css';


import Resume from './Resume';
import Picture from './Picture';
import './Card.css'





function Karte(props) {
  return (
    <Card className='Card'>
      <Card.Body>
        <div style={{marginBottom: '30px'}}>
          <Card.Title>
            <Name name = {props.Item.vorname + ' ' + props.Item.nachname} />
          </Card.Title>
        </div>

        <div style={{marginBottom: '30px'}}>
          <Picture logo= {props.Item.imageurl} />
        </div>
         
        <Resume Bezeichnung = 'Erfahrung' Wert = {props.Item.erfahrung}/>
        <Resume Bezeichnung = 'Standort' Wert = {props.Item.standort}/>
        <Resume Bezeichnung = 'Wartezeit' Wert = {props.Item.wartezeit}/>
      </Card.Body>
    </Card>
  );
}

export default Karte;